import Link from "next/link";
import { FC } from "react";

import { AnimatedIcon } from "../../components/Button";

export const TLHBanner: FC = () => {
  return (
    <Link
      href="/tax-loss-harvesting"
      className="lg:text-book20 bg-frecNeon group flex h-24 items-center justify-center border-b border-black px-6 text-[18px]"
    >
      Find out how much you could save on taxes <AnimatedIcon />
    </Link>
  );
};
