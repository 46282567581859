import { useEffect, useState } from "react";
import { flushSync } from "react-dom";

interface Props {
  value: number;
  formatter?: (n: number) => string;
  widthChangeSpeedMs?: number;
  changeDelayMs?: number;
  spinSpeedMs?: number;
}

const FORMATTER = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const SPINDLE_CHARS = [
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
  "0",
  "", // This cannot be first for some reason.
];

const ALL_CHARS = [
  // This must be first to ensure you only spin through these characters.
  ...SPINDLE_CHARS,

  ...[".", ","],
];

export const Odometer = ({
  formatter,
  value,
  changeDelayMs = 0,
  widthChangeSpeedMs = 100,
  spinSpeedMs = 500,
}: Props) => {
  const valueChars = (formatter ?? FORMATTER.format)(value).split("");

  // Need to measure the size of every character we might render so we can set
  // the height and widths properly.
  const [measureElement, setMeasureElement] = useState<HTMLDivElement>();
  const [letterRects, setLetterRects] = useState<
    { width: number; height: number }[]
  >([]);
  useEffect(() => {
    if (!measureElement) return;
    const resetRects = () => {
      setLetterRects(
        Array.from(measureElement.children)
          .map((c) => c.getBoundingClientRect())
          .map((rect) => ({
            height: Math.ceil(rect.height),
            width: Math.ceil(rect.width),
          })),
      );
    };
    const resizeObserver = new ResizeObserver(() =>
      flushSync(() => resetRects()),
    );
    resizeObserver.observe(measureElement);
    resetRects();
    return () => {
      resizeObserver.disconnect();
    };
  }, [measureElement]);

  const maxHeight = letterRects.length
    ? Math.max(...letterRects.map((r) => r.height))
    : 0;

  const totalWidth = valueChars
    .map((c) => letterRects[ALL_CHARS.indexOf(c)])
    .map((r) => r?.width || 0)
    .reduce((s, n) => s + n, 0);

  // We always render the maximum number of digits we've seen to prevent
  // characters from suddenly disappearing when the length of the string we want
  // to render decreases.
  const [maxDigits, setMaxDigits] = useState(0);
  useEffect(() => {
    // We initially render a position as an empty string so the value can spin
    // to the value we want it to be rather than the position being set to the
    // first character it is set to.
    const id = setTimeout(
      () => setMaxDigits(Math.max(maxDigits, valueChars.length)),
      0,
    );
    return () => {
      clearTimeout(id);
    };
  }, [maxDigits, maxHeight, valueChars]);

  return (
    <>
      <div
        aria-label={valueChars.join("")}
        className="inline-flex select-none flex-row-reverse overflow-hidden"
        style={{
          height: maxHeight,
          width: totalWidth + "px",

          ...(maxDigits && {
            transitionProperty: "width",
            transitionDuration: widthChangeSpeedMs + "ms",
            transitionTimingFunction: "linear",
            transitionDelay: changeDelayMs + "ms",
          }),
        }}
      >
        {valueChars
          // Render backwards to make the width changes appear from the left.
          .reverse()
          .concat(Array(Math.max(0, maxDigits - valueChars.length)).fill(""))
          .map((c, i) => {
            if (maxDigits && i >= maxDigits) c = "";
            const orderIndex = ALL_CHARS.indexOf(c);
            return (
              <div
                className="flex-0"
                key={i}
                style={{
                  transform: `translate(0px,${-orderIndex * maxHeight}px)`,
                  width: (letterRects[orderIndex]?.width || 0) + "px",
                  ...(maxDigits &&
                    SPINDLE_CHARS.includes(c) && {
                      transitionProperty: "width, transform",
                      transitionDuration: [
                        widthChangeSpeedMs + "ms",
                        spinSpeedMs + "ms",
                      ].join(", "),
                      transitionTimingFunction: "linear, ease-in-out",
                      transitionDelay: `${changeDelayMs}ms, ${changeDelayMs}ms`,
                    }),
                }}
              >
                {ALL_CHARS.map((c2) => (
                  <div
                    key={c2}
                    className="aria-hidden flex items-center overflow-clip text-center font-medium"
                    style={{ height: maxHeight + "px" }}
                  >
                    {c2}
                  </div>
                ))}
              </div>
            );
          })}
      </div>
      <div className="aria-hidden absolute h-0 overflow-hidden">
        <div ref={(e) => setMeasureElement(e || undefined)}>
          {ALL_CHARS.map((c2) => (
            <span key={c2}>{c2}</span>
          ))}
        </div>
      </div>
    </>
  );
};
