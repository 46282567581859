import { UilInfoCircle, UilQuestionCircle } from "@iconscout/react-unicons";
import { Drawer, FloatingPosition, Popover } from "@mantine/core";
import { FC, PropsWithChildren, useState } from "react";

import { useIsMobile } from "../hooks/useIsMobile";
import { classNames } from "../utils/classNames";

type InfoProps = {
  className?: string;
  width?: number;
  iconSize?: number;
  text?: string;
  useQuestionMarkIcon?: boolean;
  position?: FloatingPosition;
  withArrow?: boolean;
};
export const Info: FC<PropsWithChildren<InfoProps>> = ({
  className,
  children,
  width = 350,
  iconSize = 20,
  text,
  useQuestionMarkIcon,
  position = "bottom",
  withArrow = false,
}) => {
  const isMobile = useIsMobile();
  const [opened, setOpened] = useState(false);
  const target = (
    <button
      title="Info"
      className={classNames(
        "hover:text-frecBlack inline-flex items-center justify-end align-baseline",
        text ? "gap-1" : "h-4 w-4",
        className,
      )}
      onClick={() => setOpened(true)}
    >
      {text}{" "}
      {useQuestionMarkIcon ? (
        <UilQuestionCircle
          height={iconSize}
          width={iconSize}
          className="h-4 w-4"
        />
      ) : (
        <UilInfoCircle height={iconSize} width={iconSize} className="h-4 w-4" />
      )}
    </button>
  );

  return isMobile ? (
    <>
      {target}
      <Drawer
        className="text-sm"
        classNames={{ content: "!h-auto p-6 rounded-t-2xl bg-frecXXLightGray" }}
        position="bottom"
        opened={opened}
        onClose={() => setOpened(false)}
        title={text}
        withCloseButton={false}
        transitionProps={{ transition: "slide-up" }}
      >
        {children}
      </Drawer>
    </>
  ) : (
    <Popover
      opened={opened}
      position={position}
      width={width}
      onClose={() => setOpened(false)}
      classNames={{
        dropdown:
          "text-frecXDarkGray text-sm frec-shadow rounded-lg bg-frecWhite !pr-6",
      }}
      withArrow={withArrow}
      withinPortal
    >
      <Popover.Target>{target}</Popover.Target>
      <Popover.Dropdown>{children}</Popover.Dropdown>
    </Popover>
  );
};
