import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

import { TestimonialCategory } from "../content/testimonials";
import { PostType } from "../utils/blogApi";
import { classNames } from "../utils/classNames";
import { FrecWebPath } from "../utils/paths";

export const BlogCard: FC<{ post: PostType; className?: string }> = ({
  post,
  className,
}) => {
  return (
    <Link href={`${FrecWebPath.blog}/${post.slug}`}>
      <div
        className={classNames(
          "dotted-v hover:bg-frecDarkBeige/50 h-full p-6 lg:p-16 lg:!pb-0",
          className,
        )}
      >
        <div className="bg-frecDarkBeige w-full">
          {post.featured_image ? (
            <Image
              src={post.featured_image}
              width={391}
              height={255}
              className={classNames(
                "h-[155px] w-[214px] lg:h-[255px] lg:w-[391px]",
                post.categories.includes(TestimonialCategory)
                  ? "object-none object-left"
                  : "object-cover",
              )}
              alt=""
            />
          ) : null}
        </div>
        <h3 className="mb-4 mt-8 text-[16px] lg:text-[24px]">{post.title}</h3>
      </div>
    </Link>
  );
};
