import {
  DirectIndexType,
  useDirectIndexingColor,
} from "@frec-js/common-client";
import { classNames } from "@frec-js/common-web";
import { FC, useMemo } from "react";

import Image from "next/image";

export const DirectIndexingIcon: FC<{
  type?: DirectIndexType;
  className?: string;
  size?: "xs" | "sm" | "md";
}> = ({ type = DirectIndexType.Sp500, className, size }) => {
  const color = useDirectIndexingColor(type);
  const iconSize =
    size === "xs" ? "w-4 h-4" : size === "sm" ? "w-5 h-5" : "w-6 h-6";

  const icon = useMemo(() => {
    switch (type) {
      case DirectIndexType.Sp500:
      case DirectIndexType.CrspLargeCap:
      case DirectIndexType.Russell_1000:
        return (
          <Image
            width={24}
            height={24}
            alt="Large cap icon"
            className={iconSize}
            src="/webstatic/svg/s&p500.svg"
          />
        );
      case DirectIndexType.SpInfoTech:
        return (
          <Image
            width={24}
            height={24}
            alt="S&P info tech icon"
            className={iconSize}
            src="/webstatic/svg/s&pInfoTech.svg"
          />
        );
      case DirectIndexType.CrspSmallCap:
      case DirectIndexType.Russell_2000:
        return (
          <Image
            width={24}
            height={24}
            alt="Small cap icon"
            className={iconSize}
            src="/webstatic/svg/shop.svg"
          />
        );
      case DirectIndexType.Smh:
        return (
          <Image
            width={24}
            height={24}
            alt="SMH icon"
            className={iconSize}
            src="/webstatic/svg/cpu.svg"
          />
        );
      case DirectIndexType.CrspIssLargeCapEsg:
        return (
          <Image
            width={24}
            height={24}
            alt="ESG icon"
            className={iconSize}
            src="/webstatic/svg/tree.svg"
          />
        );
      case DirectIndexType.Russell_3000:
        return (
          <Image
            width={24}
            height={24}
            alt="Russell 3000 icon"
            className={iconSize}
            src="/webstatic/svg/chart-donut.svg"
          />
        );
      case DirectIndexType.SpAdrEm:
      case DirectIndexType.SpAdrDm:
        return (
          <Image
            width={24}
            height={24}
            alt="S&P ADR icon"
            className={iconSize}
            src="/webstatic/svg/globe.svg"
          />
        );
      case DirectIndexType.CrspMidCap:
        return (
          <Image
            width={24}
            height={24}
            alt="Mid cap icon"
            className={iconSize}
            src="/webstatic/svg/building-office.svg"
          />
        );
      case DirectIndexType.SpShariah:
        return (
          <Image
            width={24}
            height={24}
            alt="S&P Shariah icon"
            className={iconSize}
            src="/webstatic/svg/scales.svg"
          />
        );
      case DirectIndexType.CrspTotalMarket:
        return (
          <Image
            width={24}
            height={24}
            alt="Total market icon"
            className={iconSize}
            src="/webstatic/svg/chart-donut.svg"
          />
        );
      default:
        return null;
    }
  }, [type, iconSize]);

  return (
    <div
      className={classNames(
        "flex h-10 w-10 flex-shrink-0 items-center justify-center self-center rounded-full",
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {icon}
    </div>
  );
};
