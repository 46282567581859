import { useGetAvailableIndexTypesQuery } from "@frec-js/common-client";
import { useMemo } from "react";

export const useAvailableIndices = () => {
  const { data, loading } = useGetAvailableIndexTypesQuery();
  return useMemo(
    () => ({ availableIndices: data?.getAvailableIndexTypes, loading }),
    [data?.getAvailableIndexTypes, loading],
  );
};
