import Link from "next/link";
import { FC } from "react";

import { FootnoteAnchor, IndexFootnotes } from "../../components/Anchor";
import { Footnote } from "../../components/Link";
import { FrecWebPath } from "../../utils/paths";

export const RoboadvisorBanner: FC = () => {
  return (
    <section className="bg-frecBlue border-frecBlack text-frecBeige flex justify-center border-y px-6 py-4 text-center text-sm uppercase lg:text-base">
      <Link href={FrecWebPath.whitepaperEtfs}>
        Harvest up to 2x more tax losses than robo-advisors, for less than half
        the cost
      </Link>
      <Footnote anchor={FootnoteAnchor.TwoXMoreDisclaimer}>
        <sup>{IndexFootnotes.withTwoXMoreDisclaimer}</sup>
      </Footnote>
    </section>
  );
};
