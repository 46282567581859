import { UilCheck, UilMinus, UilUserCircle } from "@iconscout/react-unicons";
import { FC, ReactNode } from "react";

import FrecLogo from "../../public/webstatic/logo/frec.svg";
import { Info } from "../../components/Info";

export const DirectIndexTable: FC = () => {
  return (
    <section className="grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[96px_auto_1fr_32px] pt-8 lg:pt-0">
      <div className="">{/* empty */}</div>
      <div className="dotted-v px-6 pb-8 lg:mx-12 lg:border-x lg:px-16">
        <h2 className="h2 text-center">See how we’re different</h2>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="dotted-v text-[12px] md:text-[16px] lg:mx-12 lg:border-x">
        <div className="dotted-v border-x-none lg:mx-12 lg:border-x">
          <div className="dotted-h grid h-full grid-cols-[3fr_2fr_2fr_2fr] grid-rows-[repeat(5,100px_1px)] border-y font-normal">
            <div className="dotted-v flex items-center border-r bg-[#E9E5DD] px-4 lg:px-9">
              Features
            </div>
            <div className="dotted-v bg-frecNeon flex items-center justify-center border-r px-2 lg:px-9">
              <FrecLogo
                className="w-[33px] md:w-[84px]"
                width="84"
                src="/webstatic/logo/frec.svg"
                aria-label="Frec"
              />
            </div>
            <div className="dotted-v flex items-center justify-center border-r bg-[#E9E5DD] px-2 lg:px-9">
              ETFs
            </div>
            <div className="dotted-v flex items-center justify-center bg-[#E9E5DD] px-2 text-center lg:px-9">
              Robo-advisors
            </div>
            {/* <TableRow label="Low minimums" etfs roboAdvisors frec /> */}
            <TableRow
              label={<span>Invest in multiple direct indices</span>}
              etfs
              frec
              roboAdvisors
            />
            <TableRow
              label="Daily tax loss harvesting of individual stocks"
              frec
            />
            <TableRow
              label="Customize by adding or excluding stocks and sectors"
              frec
            />
            <TableRow
              label={
                <span>
                  Transfer stock into direct indexing with tax efficiency{" "}
                  <Info className="align-middle" position="bottom-start">
                    <div className="text-xs">
                      Eligible for all direct index portfolios that meet the
                      minimum investment amount.
                    </div>
                  </Info>
                </span>
              }
              frec
            />
          </div>
        </div>
        <div
          className="text-frecBlack/50 px-6 py-4 text-[12px] lg:px-16 lg:text-[14px]"
          data-nosnippet
        >
          A robo-advisor is considered a digital platform that provides
          automated, algorithm-driven investment services with little human
          interaction. Frec Advisers is considered a robo-advisor. Robo-advisors
          typically charge an advisory fee based on assets in the portfolio.
          However, ETFs are exchange traded funds that are securities that trade
          on stock exchanges and typically charge a management fee shown as its
          expense ratio.
        </div>
        <div className="my-14 flex w-full justify-center px-6">
          <span className="bg-frecDarkBeige rounded-full px-6 py-2 text-sm">
            <UilUserCircle className="text-frecXDarkGray mr-2 inline h-4 w-4 align-middle" />
            <span className="align-middle font-medium">Account support</span>
            <span className="ml-2 align-middle font-normal">
              We support individual, business, and trust accounts.
            </span>
          </span>
        </div>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className=""></div>
      <div className="dotted-v lg:mx-12 lg:border-x">{/* empty */}</div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const TableRow: FC<{
  label: ReactNode;
  etfs?: boolean;
  roboAdvisors?: boolean;
  frec?: boolean;
}> = ({ label, etfs, roboAdvisors, frec }) => {
  return (
    <>
      <div className="dotted-h border-b">{/* empty */}</div>
      <div className="dotted-h bg-frecNeon border-b">{/* empty */}</div>
      <div className="dotted-h col-span-2 border-b">{/* empty */}</div>
      <div className="dotted-v flex items-center text-balance border-r px-4 lg:px-9">
        {label}
      </div>
      <div className="dotted-v bg-frecNeon flex items-center justify-center border-r px-2 lg:px-9">
        <Check checked={frec} />
      </div>
      <div className="dotted-v flex items-center justify-center border-r px-2 lg:px-9">
        <Check checked={etfs} />
      </div>
      <div className="dotted-v flex items-center justify-center px-2 lg:px-9">
        <Check checked={roboAdvisors} />
      </div>
    </>
  );
};

const Check: FC<{ checked?: boolean }> = ({ checked }) => {
  return checked ? (
    <UilCheck
      className="text-frecBlack h-6 w-6 lg:h-8 lg:w-8"
      aria-label="Yes"
    />
  ) : (
    <UilMinus
      className="text-frecBlack/30 h-6 w-6 lg:h-8 lg:w-8"
      aria-label="No"
    />
  );
};
