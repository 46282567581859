import Image, { StaticImageData } from "next/image";
import { FC } from "react";

import { Button } from "../../components/Button";
import Ashley from "../../public/webstatic/images/team/ashley-color.jpg";
import Dimitri from "../../public/webstatic/images/team/dimitri-color.jpg";
import { classNames } from "../../utils/classNames";
import { windowIfAvailable } from "../../utils/windowIfAvailable";

const licensedProfessionals = {
  ashley: {
    image: Ashley,
    name: "Ashley Rodabaugh",
    title: "Chief Compliance Officer",
  },
  dimitri: {
    image: Dimitri,
    name: "Dimitri Rodrigo",
    title: "Head of Brokerage Operations",
  },
};

export const ScheduleCall: FC<{
  title?: string;
  description?: string;
  className?: string;
}> = ({
  title = "Have questions?",
  description = "Meet with one of our licensed professionals who can give you an overview of Frec, answer your questions, and get you started with a free portfolio analysis.",
  className,
}) => {
  return (
    <section
      className={classNames(
        "grid grid-cols-[auto_minmax(0,_86rem)_auto]",
        className,
      )}
    >
      {/* row */}
      <div className=""></div>
      <div className="dotted-v grid grid-rows-[auto_auto] lg:mx-12 lg:grid-cols-2 lg:grid-rows-none lg:border-x">
        <div className="dotted-v flex h-full flex-col justify-center gap-8 px-6 pt-12 lg:ml-12 lg:border-r lg:pb-24 lg:pt-24">
          <h2 className="h2">{title}</h2>
          <p className="lg:text-book20">{description}</p>
          <Button
            onClick={() => windowIfAvailable?.open("/15-min", "_blank")}
            className="w-full lg:w-fit"
            variant="blueoutline"
          >
            Schedule a call
          </Button>
        </div>
        <div className="px-6 py-12">
          <div className="relative flex h-full min-h-[400px] flex-col items-center justify-center overflow-x-hidden">
            <FrecklePanel
              info={licensedProfessionals.dimitri}
              className={classNames(
                "frec-shadow h-[200px] w-[200px] lg:h-[250px] lg:w-[250px]",
                "ml-40 mt-24 lg:ml-60",
              )}
            />
            <div className="relative">
              <FrecklePanel
                info={licensedProfessionals.ashley}
                className={classNames(
                  "frec-shadow relative h-[200px] w-[200px] lg:h-[250px] lg:w-[250px]",
                  "right-20 -mt-20",
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const FrecklePanel: FC<{
  info: { name: string; image: StaticImageData };
  className?: string;
}> = ({ info: { name, image }, className }) => {
  return (
    <div
      key={name}
      className={classNames(
        "absolute h-[200px] w-[200px] shrink-0 rounded-3xl lg:h-[250px] lg:w-[250px]",
        className,
      )}
    >
      <Image
        src={image}
        className="absolute h-full w-full rounded-3xl object-cover"
        alt={name}
      />
      {name === "Ashley Rodabaugh" && (
        <div className="border-frecMidGray absolute -bottom-8 right-0 rounded-lg border bg-white p-6">
          How can we help?
        </div>
      )}
    </div>
  );
};
